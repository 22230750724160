.homeContainer {
    background-color: #ffffff;
    padding-top: 1rem;
}

.mobileLandscape {
    /* padding-top: 2vh !important; */
}

.mobileLandscapeContainerWrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    padding: 0 5rem;
    height: calc(100vh - 70px);
}

.homeWrapperContainer {
    display: flex;
}

.homeContainerMobileWrapper {
    display: block;
}

.mobileLandscapeContainer {
    margin-top: 1rem;
    display: flex;
    position: relative;
    gap: 1rem;
    color: white;
}

.mobileHomeImgContainer {
    flex: 0 0 20%;
    padding: 0.3rem 0.3rem;
    padding-top: .5rem;
    height: fit-content;
}

.mobileCanvasContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    padding: 0 0.3rem;
    padding-top: .5rem;
    overflow-y: scroll;
    height: calc(100vh - 102px);
}

.mobileCanvasContainer::-webkit-scrollbar {
    width: 6px;
}

.mobileCanvasContainer::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
}

.mobileCanvasContainer::-webkit-scrollbar-thumb {
    background: rgba(28, 34, 75, 0.8) !important;
    border-radius: 3px;
}

.mobileCatalogImgContainer {
    padding: 0.3rem 0.3rem;
    padding-top: .5rem;
}

.mobileCatalog {
    flex: 0 0 10%; /* Reduced from 20% to 10% */
}

.promoContainer {
    padding-top: 10vh;
    min-width: 16vw;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 20%;
    background-color: #f7f7f7;
}

.promoContainer img {
    width: 4vw; /* Reduced from 8vw to 4vw */
}

.promoHeader {
    padding: 0.4rem 0.6rem 0;
    font-size: 19px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.promoTextContainer {
    font-size: 14px;
}

.promoImg {
    margin-bottom: 2vh;
}

.actionBtnContainer {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    height: 45px;
    margin-bottom: 0.8rem;
}

.actionBtnContainer svg {
    cursor: pointer;
    justify-content: space-between;
}

.actionIcon {
    display: flex;
    gap: 10px;
}

.actionBtnForMobile {
    margin: 1rem 0;
}

.requestQuoteContainer {
    position: absolute;
    z-index: -1;
    top: -200px;
}

.portraitContainer {
    overflow: hidden;
}

.portraitItem {
    width: 46vw;
    float: left;
}

.frontDoorGIF {
    margin: 15px 0;
}

.filterActive {
    background: red;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    top: -3px;
    right: 3px;
    position: relative;
}

@media screen and (min-width: 1200px) {
    .promoContainer {
        border-left: 1px solid #ebebeb;
    }
}

@media screen and (max-width: 768px) {
    .actionBtnContainer {
        justify-content: start;
    }
}
