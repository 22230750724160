.interiorcomp {
  position: relative;
  background-color: var(--primary);
  min-height: calc(100dvh - 63px) !important;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}
.bottomRight {
  position: absolute;
  bottom: 0px;
  right: 5px;
}
.uploadImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.uploadImage p {
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  font-weight: medium;
  letter-spacing: 0px;
  color: #000000;
}
.uploadImage span {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  color: #110e2e;
}
.DropdownButtonWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.interiorStyle {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.dropdownList {
  color: #000000 !important;
  font-size: 14px;
}
.DropdownButtonWrapper button {
  padding: 13px;
  border-radius: 0;
}
.DropdownButtonWrapper button:disabled {
  background-color: #d8d8d8 !important;
  color: white;
  opacity: 1;
  cursor: not-allowed;
}
.previewImage {
  height: 50vh;
  width: 100%;
  object-fit: contain;
}
.selectedImg {
  height: 40vh !important;
}
@media only screen and (min-width: 768px) {
  .previewImage {
    height: 60vh;
  }
  .selectedImg {
    height: 60vh !important;
  }
}
@media only screen and (min-width: 1024px) {
  .interiorcomp {
    background-color: var(--primary);
    min-height: calc(100dvh - 70px) !important;
    display: flex;
    align-items: center;
    color: #fff;
  }
  .previewImage {
    height: 60vh;
  }
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 1220px) {
  .homeContainer {
    height: 100%;
  }
  .button {
    min-width: 250px;
    width: 100%;
  }
}
@media screen and (min-width: 1700px) {
  .interiorcomp {
    min-height: calc(100dvh - 70px) !important;
  }
}

.fullScreenPreview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullScreenImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.previewControls {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 10000;
}

.previewControls button {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  z-index: 10001;
}

.previewControls button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}