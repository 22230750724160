.homeContainer {
    height: 100vh;
    background-color: #ffffff;
    padding: 10vh 15px 0;
}

.homeContainerWrapper {
    /* display: flex; */
}

.mobileLandscape {
    padding-top: 2vh !important;
}

.mobileLandscapeContainerWrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 80%;
    padding: 0 6px;
}

.homeWrapperContainer {
    display: flex;
}

.homeContainerMobileWrapper {
    display: block;
}

.mobileLandscapeContainer {
    margin-top: 10vh;
    display: flex;
    position: relative;
    justify-content: space-evenly;
}

.mobileLandscapeContainer > div {
    padding-left: 10px;
}

.mobileHomeImgContainer {
    flex: 0 0 22%;
}

.mobileCanvasContainer {
    flex: 0 0 min(60%, 640px);
    justify-content: center;
}

.mobileCatalogImgContainer {
    flex: 0 0 10%; /* Ensure this is changed */
    padding-right: 10px;
}

.mobileCatalog {
    flex: 0 0 10%; /* Ensure this is changed */
}

.mobileLandscapeContactContainer {
    width: 100%;
}

.promoContainer {
    padding-top: 10vh;
    min-width: 16vw;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 20%;
}

.promoContainer img {
    width: 8vw;
}

.promoHeader {
    padding: 0.4rem 0.6rem 0;
    font-size: 19px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.promoTextContainer {
    font-size: 14px;
}

.promoImg {
    margin-bottom: 2vh;
}

.actionBtnContainer {
    display: flex;
    justify-content: flex-end;
    justify-content: space-between;
    margin: 0 0 1rem;
}

.actionBtnContainer svg {
    margin-left: 1.4rem;
    cursor: pointer;
}

.actionBtnForLandscape {
    /* margin: 0; */
}

.actionBtnForMobile {
    margin: 1rem 0;
}

.requestQuoteContainer {
    position: absolute;
    z-index: -1;
    top: -200px;
}

.portraitContainer {
    overflow: hidden;
}

.portraitItem {
    width: 46vw;
    float: left;
}
