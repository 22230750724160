.toastrContainer {
    font-size: 16px;
    box-sizing: border-box;
    position: fixed;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fbf9f9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 600px;
    background-color: #070b88;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    word-wrap: break-word;
}

.toastrMessageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.toastrMessageContainer p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    white-space: normal;
    word-wrap: break-word;
}

.error {
    background-color: red;
}

.success {
    background-color: green;
}

.close {
    margin-top: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #faf9f9;
}
