.titleContainer {
    font-weight: bold;
    text-align: start;
    /* font-size: 14px; */
    font-size: 1rem;
    padding-top: 0.6rem;
}

.mobileTitle {
    /* font-size: 12px; */
    font-size: 0.8rem;
    height: 50px;
}