.inputFileContainer input[type="file"] {
    display: none;
}

.customFileUpload {
    /* display: inline-block; */
    cursor: pointer;
    color: #ffffff;
    background-color: #4C84FF;
    text-align: center;
    display: flex;
    justify-content: center;
    /* height: 100%; */
    height: 10vh;
    margin-top: 22px;
    align-items: center;
}

.customFileUpload img {
    /* width: 62px;
    height: 100%; */
    align-items: center;
    padding-top: 12px;
}

.isLandscapeViewImg {
    /* width: 48px !important;
    height: 48px !important; */
}

.isDesktopViewImg {
    width: 74px !important;
    height: 74px !important;
}

.inputContainer {
    margin-bottom: 1.6rem;
}

.inputFileContainer {
    margin-bottom: 1rem;
}

.inputContainer input {
    font-size: 14px;
}

.inputError {
    position: absolute;
    bottom: -18px;
    font-size: 12px;
    color: red;
}

.iPadProCls {
    width: 7.6rem !important;
    height: 7.6rem !important;
}

.ipadLandscape {
    width: 4.4rem !important;
    height: 4.4rem !important;
}


@media only screen and (max-width: 768px) {
    .customFileUpload img {
        width: 2rem;
    }

    .customFileUpload {
        margin: 0;
        margin-top: 14px;
    }

}