.dropdown button {
  width: 100%;
  text-align: start;
  background-color: #1c224b;
}
.generateBtn {
  margin-top: 225px;
}

.rightIcon {
  padding-left: 95%;
}

/* .selectedImg {
  border-radius: 30px;
} */
.exteriorcomp {
  position: relative;
  background-color: var(--primary);
  min-height: calc(100dvh - 63px) !important;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}
.bottomRight {
  position: absolute;
  bottom: 0px;
  right: 5px;
}
.uploadImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.uploadImage p {
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  font-weight: medium;
  letter-spacing: 0px;
  color: #000000;
}
.uploadImage span {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  color: #110e2e;
}
.DropdownButtonWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.exteriorStyle {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.dropdownList {
  color: #000000 !important;
  font-size: 14px;
}

.DropdownButtonWrapper button {
  padding: 13px;
  border-radius: 0;
}

.DropdownButtonWrapper button:disabled {
  background-color: #d8d8d8 !important;
  color: white;
  opacity: 1;
  cursor: not-allowed;
}

.previewImage {
  height: 50vh;
}

.selectedImg {
  height: 40vh !important;
}

@media only screen and (min-width: 768px) {
  .previewImage {
    height: 60vh;
  }

  .selectedImg {
    height: 60vh !important;
  }
}

@media only screen and (min-width: 1024px) {
  .exteriorcomp {
    background-color: var(--primary);
    min-height: calc(100dvh - 70px) !important;
    display: flex;
    align-items: center;
    color: #fff;
  }

  .previewImage {
    height: 60vh;
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1220px) {
  .homeContainer {
    height: 100%;
  }

  .button {
    min-width: 250px;
    width: 100%;
  }
}

@media screen and (min-width: 1700px) {
  .exteriorcomp {
    min-height: calc(100dvh - 70px) !important;
  }
}

.bottomRight {
  position: absolute;
  bottom: 0px;
  right: 30px;
}
.uploadImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}
.uploadImage p {
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  font-weight: medium;
  letter-spacing: 0px;
  color: #000000;
}
.uploadImage span {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  color: #110e2e;
}
.DropdownButtonWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.exteriorStyle {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.detailZoomButton {
  top: 10px;
  left: 30px;
  z-index: 10;
}

.detailZoomButton button {
  border: none;
  margin: 0.4rem 0 0 0.4rem;
  background-color: #ffffff;
  color: black;
  border-radius: 50%;
  height: 2.2rem;
  width: 2.2rem;
  box-shadow: 1px 2px 10px 1px #999999;
  font-size: 1.4rem;
  padding: 0;
  opacity: 0.8;
}

.zoomButtons {
  top: 10px;
  left: 30px;
  z-index: 10;
}
.zoomButtons button {
  border: none;
  margin: 0.4rem 0 0 0.4rem;
  background-color: #ffffff;
  color: black;
  border-radius: 50%;
  height: 2.2rem;
  width: 2.2rem;
  box-shadow: 1px 2px 10px 1px #999999;
  font-size: 1.4rem;
  padding: 0;
  opacity: 0.8;
}

.zoomButtons button:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.previewCard {
  overflow: hidden;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-img-top,
.previewCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.uploadImageContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadImageContainer img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.uploadedImage {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.fullScreenPreview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullScreenImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.previewControls {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 10000;
}

.previewControls button {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  z-index: 10001;
}

.previewControls button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}