.sliderContainer {
  width: 100%;
}

.sliderContainer {
  padding: 0 10%;
  margin: 1vh 0;
}

.sliderContainer {
  max-width: 100%;
  margin: auto;
}

.slideImage {
  object-fit: cover;
}

.sliderContainer .slick-slider,
.sliderContainer .slick-list,
.sliderContainer .slick-track,
.sliderContainer .slick-slide {
  height: 100%;
}

@media screen and (max-width: 480px) {
  .sliderContainer {
    display: none;
  }

  .slideImage {
    height: 200px;
  }
}

@media screen and (min-width: 1024px) {
  .sliderContainer {
    padding: 0 10%;
    margin: 1vh 0;
    object-fit: contain !important;
  }

  .slideImage {
    height: 240px;
  }
}

@media screen and (min-width: 1440px) {
  .sliderContainer {
    padding: 0 5%;
    margin: 1vh 0;
  }

  .slideImage {
    height: 300px;
  }

  .blurredDivBefore {
    padding: 30px 60px;
  }
}

@media screen and (min-width: 1700px) {
  .sliderContainer {
    padding: 0 5%;
    margin: 1vh 0;
  }

  .slideImage {
    height: 320px;
  }
}

@media screen and (min-width: 1920px) {
  .sliderContainer {
    padding: 0 2%;
    margin: 1vh 0;
  }

  .slideImage {
    height: 460px;
  }
}

.blurredDivBefore {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: auto;
  padding: 20px 40px;
  font-size: 20px;
  height: 50px; /* Adjust height as needed */
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Apply blur effect */
  color: white; /* White text color */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.blurredDivAfter {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: auto;
  padding: 20px 40px;
  font-size: 20px;
  height: 50px; /* Adjust height as needed */
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Apply blur effect */
  color: white; /* White text color */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
