.swiperWrapper {
    /* height: 24vh; */
    /* margin-top: 4vh; */
    height: 66vh;
    /* height: 52vh;
    min-height: 52vh;
    max-height: 66vh; */
    position: relative;
}

.btnContainer button {
    position: absolute;
    z-index: 9;
    border-radius: 50%;
    border: none;
    padding: 0.6rem 0.8rem;
    box-shadow: 0px 2px 8px 0px #333333;
}

/* button {
    position: absolute;
    z-index: 9;
    border-radius: 50% !important;
    border: none;
    padding: 0.6rem 0.8rem;
    box-shadow: 0px 2px 8px 0px #333333;
} */

.btnContainer {
    display: flex;
    justify-content: center;
    width: 14vw;
}

.prevBtn {
    top: 2px;
}

.nextBtn {
    bottom: 0;
}

.swiper-container1 {
    height:60vh;
    overflow: hidden;
}

.homeSwiperWrapper {
    height: 51vh;
    max-height: 51vh;
}

.responsiveSwipper {
    height: auto;
    max-height: auto;
}

.swiperTitle {
    font-weight: bold;
    font-size: 1rem;
    padding: 10px;
    background-color: #fafafa;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin: 1rem 0;
}

.swiperSlide {
    /* width: 100% !important; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 8rem !important; */
    height: 100% !important;
    /* height: 14vh !important; */
}

.responsiveSwipperSlide {
    height: 12vh !important;
    width: 16vh !important;
}

.swiperImgContainer {
    /* width: 15rem; */
    height: 100%;
    padding: 0.2rem;
}

.swiperImg {
    width: 100%;
    height: 100%;
    animation: none;
    background-color: #c0c0c0;
}

.frontdoorCatelogImg {
	text-align: center;
}
.frontdoorCatelogImg > img:first-child {
	width: 100px;
	height: 100%;
	min-width: 25%;    
    object-fit: contain;
    animation: none;
    background-color: #c0c0c0;
}
 
@keyframes changeBackgroundColor {
    0% {
      background-color: #ffffff;
    }
    25% {
      background-color: #cccccc;
    }
    50% {
      background-color: #aaaaaa;
    }
    75% {
        background-color: #888888;
    }
    100% {
        background-color: #666666;
    }
}

.selectedSwiper {
    border: 2px solid #32bbbc;
    border-radius: 2px;
    background-color: #32bbbc;
}

.fullWidth {
    width: 100%;
}

.swiperComWrapper {
    /* height: 66vh; */
    /* height: 82vh !important; */
    height: 74vh !important;
    overflow: hidden;
    scroll-behavior: smooth;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.swiperComWrapper div:last-child > div {
    background-color: rgba(28, 34, 75, 0.8) !important;
}

.swiperItemContainer {
    padding: 5px 0;
    height: auto;
    width: 100%;
    /* height: 12vh; */
    /* width: 14vw; */    
    /* width: 80%; */
    position: relative;
}

.houseImgWrapper {
    /* height: 46vh; */
    /* height: 63vh !important; */
    height: 53vh !important;
    margin-top: 0.2rem;
    overflow: hidden;
}

.houseImgWrapperLandscape {
    height: 43vh;
}

.housePrevBtn {
    /* top: 18vh; */
}

.houseBtnContainer {
    /* width: 12vw; */
    width: 100%;
}

.houseImgHeight {
    /* height: 24vh; */
    /* width: 90% !important; */
    weight: 100%;
    height: auto;    
}

.mobileSwiperItem {
    width: 100%;
}

.singleDoorImgWrapper {
    width: 74% !important;
}

.btnContainerForSingleDoor {
    width: 10vw;
}

.swiperComWrapperContainer {
    position: relative;
    padding-top: 0.9rem;
}

.scrollBtn {
    position: absolute;
    /* top: 2px; */
    z-index: 9;
    border-radius: 50%;
    border: none;
    padding: .6rem .8rem;
    box-shadow: 0 4px 7px 0 #333;
}

.watermark {
    position: absolute;
    /* left: 0; */
    width: 2rem;
    bottom: 0.3rem;
    right: 0;
    height: 2rem;
}

@media screen and (max-width: 576px){
    .frontdoorCatelogImg > img:first-child {
        width: auto;
    }
}
@media (min-width: 1750px) {
    .swiperComWrapper {
        height: calc(100vh - 160px) !important;
    }
    .houseImgWrapper {
        height: 66.5vh !important;
    }
}