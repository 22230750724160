/* #region css variable */
:root {
  --bgColor: #1b224a;
  --primaryButton: #3abf98;
  --secondaryButton: #4f78fe;
  --exterior: #4c84ff;
  --interior: #F0904E;
  --garageDoor: #058084;
  --entryDoor: #e4be62;
  --white: #fff;
}

/* #endregion css variable */

.homeContainer {
  padding: 0 50px 20px;
  height: calc(100dvh - 64px) !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background: var(--bgColor);
}

.contentWrapper {
  padding: 12px 0;
  text-align: center;
}

.subTitle {
  font-size: 32px;
  color: var(--white);
}

.title span {
  color: #0fbdc3;
}

.title {
  font-size: 40px;
  line-height: 60px;
  color: var(--white);
  text-transform: capitalize;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  grid-column-gap: 10px;
}

.button {
  border: 0;
  font-size: 16px;
  line-height: 1.5;
  padding: 8px 40px;
  color: var(--white);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 300px;
  border: 1px solid transparent;
}

.button:hover {
  border-color: #fff;
}

a:has(.button):hover {
  text-decoration: none;
}

.button img {
  width: 38px;
  height: 38px;
  object-fit: contain;
}

.button span {
  position: relative;
  margin-top: 5px;
}

.button span:before {
  content: "";
  display: inline-block;
  height: 1px;
  width: 94px;
  background: #ffffff;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -10px);
}

.primaryButton {
  background: var(--primaryButton);
}

.secondaryButton {
  background: var(--secondaryButton);
}

.garageDoorButton {
  background: var(--garageDoor);
}

.exteriorButton {
  background: var(--exterior);
}

.interiorButton {
  background: var(--interior);
}

.entryDoorButton {
  background: var(--entryDoor);
}

.buttonLabel {
  margin: 0px;
  letter-spacing: 1.6px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  color: #f2f2f2;
  margin-top: 8px;
}

.home-page-slider:has(.style_sliderContainer__RnKHX) {
  padding: 2% 0;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .button {
    min-width: initial;
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .button {
    min-width: 200px;
    width: 100%;
  }

  .homeContainer {
    height: calc(100dvh - 70px) !important;
  }
}

@media screen and (min-width: 1220px) {
  .homeContainer {
    height: 100%;
  }

  .button {
    min-width: 250px;
    width: 100%;
  }
}

@media screen and (min-width: 1700px) {
  .button {
    min-width: 350px;
    width: 100%;
  }

  .contentWrapper {
    padding: 50px 0 50px 0;
  }

  .title {
    font-size: 50px;
    line-height: 60px;
  }

  .homeContainer {
    height: calc(100dvh - 70px) !important;
  }
}

/* #region media query */
@media screen and (max-width: 480px) {
  .buttonWrapper {
    flex-direction: column;
    grid-row-gap: 15px;
  }

  .button {
    font-size: 16px;
    padding: 12px 30px;
    min-width: 100%;
    margin-bottom: 5px;
  }

  .logoImage {
    width: 100%;
    height: 30vh;
  }

  .title {
    font-size: 34px;
    line-height: 44px;
  }
}

/* #endregion media query */