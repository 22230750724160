.emailContainer {
    padding: 10px;
    /* margin-top: 80px; */
    /* display: none; */
    /* position: absolute;
    z-index: -1;
    top: -200px; */
    background-color: #EEEDFF;
}

.emailHeader {
    font-weight: bold;
    font-size: 8px;
    margin-left: 70px;
    /* text-align: center; */

}

.emailCustomerHeader {
    font-size: 6px;
}

.emailItem {
    font-size: 5px;
}

.emailImgContainer {
    margin: 10px 0 5px;
}

.emailImgHouse {
    width: 100px;
    height: 60px;
    margin-left: 50px;
}

.emailCatalogContainer {
    /* display: flex; */
    margin-top: 20px;
}

.emailImgCatalog {
    width: 60px;
    height: 30px;
    margin-left: 50px;
}

.emailTextCatalog {
    float: left;
    font-size: 6px;
    margin-top: 10px;
    margin-left: 5px;
}