.contactContainerWrapper {
    align-items: center;
}

.contactContainer {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #ffffff;
    justify-content: space-between;
}

.contactContainerTxt {
    font-size: 1rem;
    padding-top: 1rem;
    padding-right: 1rem;
    ;
}

.contactContainer img {
    width: 2rem;
    padding: 0 0.6rem;
    cursor: pointer;
}

.appIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.appIconContainer img {
    width: 5rem;
}

.mobileLandscapeContainer {
    display: flex;
    /* justify-content: center; */
    /* margin-left: 22%; */
    position: relative;
    justify-content: center;
    flex-direction: column;
}

.mobileLandscapeContainer img {
    width: 4rem;
    padding-top: 0.2rem
}

.contactContainerLandscape {
    flex: 0 0 43vw;
    flex-direction: row;
}

.mobileContact {
    /* flex: 0 0 61vw; */
    flex: 1;
}

.appIconContainerLandscape {
    width: 100%;
}

.disclaimerContainer {
    font-size: 0.72rem;
    padding-top: 0.4rem;
    padding-bottom: 1rem;
    margin-top: 10px;
    font-size: 0.9rem;
    /* color: #666; */
    color: white;
}

.disclaimerContainerLandscape {
    /* position: absolute;
    top: 4rem;
    width: 100%; */
    flex: 1;

}

.downloadLink {
    font-weight: bold;
    line-height: 22px;
    padding: 10px 0 0;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}

/* .contactContainerWrapperMobile {

}

.contactContainerWrapperDesktop {

} */


.leftContainer,
.rightContainer {
    display: flex;
    flex-direction: column;
}

.leftContainer {
    flex: 1;
    padding-right: 20px;
}

.rightContainer {
    display: flex;
    flex-direction: row;
    background-color: #007bff;
    /* box-shadow: -6px -27px 23px -17px #0f1330 inset; */
    height: 60px;
    width: 280px;
    justify-content: center;
}


/* Specific styles for mobile landscape */

@media only screen and (max-width: 920px) {


    .rightContainer img {
        width: 3rem;
    }

    .rightContainer {
        margin-bottom: 15px;
    }

    .contactContainer {
        margin: 0px 10px;
        flex-direction: column;

    }

    .disclaimerContainer {
        color: white;
    }
}

@media only screen and (max-width: 768px) {

    .disclaimerContainer {
        color: black;
    }
}