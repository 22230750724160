.loaderContainerWrapper {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    top: 0;
    left: 0;
}

.loaderOverlay {
    background-color: #000000;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.6;
}

.loaderContainer {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #1C224B;
    width: 35px;
    height: 35px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    margin-bottom: 6px;
}

.loaderText {
   color: #f3f3f3;
   z-index: 9;
   width: 300px;
   text-align: center;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }