.flex {
  display: flex;
}
.headerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.headerWrapper {
  height: 70px;
  z-index: 11;
  top: 0;
  position: sticky;
  background-color: black;
  border-bottom: 1px solid #058084;
  display: flex;
  align-items: center;
}

.headerMain {
  padding: 0 !important;
  gap: 55px;
}

.headerBrand {
  color: #ffffff !important;
  text-transform: uppercase;
  font-size: 1rem !important;
  padding: 0 !important;
  margin-right: 0 !important;
  position: relative;
  display: flex !important;
  align-items: center;
}

.img {
  background-color: #0f1330;
}

.headerBrand img {
  height: 7vh;
  width: 100%;
  object-fit: contain;
  padding: 0 12px;
}

.logo {
  width: 3.6rem;
  object-fit: contain;
}

.mobileOSHeader {
  height: 8vh;
}

.mobileLandscapeHeader {
  height: 70px;
}

.mobileOSLogo {
  width: 3.4rem;
}

.appNameVersion {
  margin-left: 0.4rem;
  margin-top: -0.4rem;
  display: block;
}

.navlink {
  font: normal normal normal 21px/25px;
  letter-spacing: 0px;
  color: #ffffff !important; /* Ensure the color is set to white */
  text-transform: capitalize;
}

.navlink:hover,
.navlink.active {
  color: #4c84ff !important; /* Ensure the color is set to blue on hover and active states */
  text-decoration: none;
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1220px) {
  .homeContainer {
    height: 100%;
  }

  .button {
    min-width: 250px;
    width: 100%;
  }
}

@media screen and (min-width: 1700px) {
  .headerWrapper {
    height: 70px;
  }

  .headerMain {
    padding: 0 !important;
    gap: 70px;
  }

  .navlink {
    font-size: 21px;
    letter-spacing: 0px;
    color: #ffffff !important; /* Ensure the color is set to white */
    text-transform: capitalize;
  }
}

@media screen and (max-width: 480px) {
  .headerMain {
    gap: 16px;
  }
  .navlink {
    font-size: 16px;
  }
  .mobileLandscapeHeader {
    height: 70px;
    padding: 0 15px !important;
  }
}
