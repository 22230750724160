:root {
  --primary: rgb(160, 151, 151);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
  margin: 0;
  background-color: #1c224b;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.no-padd {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.app-container {
  /* height: calc(100vh - 70px); */
  /* margin-top: 70px; */
  /* background-color: #fafafa; */
}

.canvas-container {
  height: 50vh;
  width: 50%;
  margin: 0 auto;
}

.canvas-container canvas {
  height: 100%;
  width: 100%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #000000;
  padding: 0.62rem 0.9rem;
  border-radius: 50%;
}

.mobileContactWrapper {
}

.desktopContactWrapper {
  display: flex;
  width: 100%;
}

.contactContainer {
  /* margin-left: 24%; */
  /* width: 57%; */
  width: 100%;
  /* display: flex; */
}
.desktopContactWrapper .contact {
  /* margin-left: 22%; */
  width: 100%;
}

.desktopContactWrapper .logo {
  flex: 0.85;
}

.react-transform-component,
.react-transform-component > div {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}
.react-transform-component > div {
  justify-content: center;
}

.konvajs-content {
  margin: 0 auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.outer-container {
  max-width: 800px;
  margin: auto;
  display: flex;
  border: 1px solid #0fbdc3;
  border-radius: 5px;
  overflow: hidden;
}

.outer-container > div {
  flex: 1;
  padding: 20px;
}

.outer-container .carousel-wrapper {
  background: var(--orange);
  display: flex;
  flex-direction: column;
}

.outer-container .carousel-wrapper p,
.carousel-item {
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
  line-height: 1.3;
}

.outer-container .content {
  background: var(--white);
}

.leading-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 6px;
}

.carousel {
  height: 100%;
  display: flex;
  flex: 5;
  align-items: center;
  color: var(--white);
}

.carousel-button {
  display: flex;
  align-self: center;
  padding: 10px;
  max-width: 50px;
  border-radius: 30px;
  background: var(--orange);
  border: 2px solid var(--white);
}

.carousel-button svg {
  height: 26px;
  width: 26px;
}
.carousel-button path {
  fill: var(--white);
}

.slides {
  align-self: flex-start;
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.carousel-inner {
  position: relative;
  max-height: 250px;
  height: 250px;
}

.carousel-inner::before,
.carousel-inner::after {
  content: "";
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 110px;
  pointer-events: none;
}

.carousel-inner::before {
  top: 0;
  background: linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0) 0%,
    hsla(27, 100%, 48%, 1) 100%
  );
}

.carousel-inner::after {
  bottom: 0;
  background: linear-gradient(
    0deg,
    hsla(27, 100%, 48%, 1) 0%,
    hsla(0, 0%, 100%, 0) 100%
  );
}

.carousel-item {
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  top: 112px;
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.carousel-item.visible {
  opacity: 1;
}
.slider-container {
  width: 100%;
}
.slick-slide img {
  width: 100%;
}
.slick-slider .slick-track {
  display: flex;
  gap: 20px;
}
.slick-dots li button:before {
  display: none;
}
.slick-dots li button {
  padding: 0 !important;
  background-color: transparent !important;
  height: 10px !important;
  width: 10px !important;
  border: 2px solid #fff !important;
  border-radius: 50% !important;
}
.slick-dots li.slick-active button,
.slick-dots li button:hover {
  background-color: #fff !important;
}
.uploadImageCard {
  border-radius: 0;
  box-sizing: border-box;
  background-color: #f2f2f2;
}
.uploadImageCard .card-body {
  height: 50vh;
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.uploadImageCard button {
  padding: 8px 80px;
  border-radius: 0;
}

.dropdown-toggle::after {
  position: absolute;
  left: auto;
  right: 10px;
  top: 15px;
}

.backLink {
  top: 10px;
}

.previewCard {
  height: 200px;
  border-radius: 0px;
}

.css-jp8avr-menu {
  overflow-y: auto;
  height: 6rem !important;
}

.css-1nmdiq5-menu {
  overflow-y: hidden;
  height: 15rem !important;
}
.modal-open .modal.show {
  display: flex !important;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}
.modal-content {
  border-radius: 0;
  overflow: hidden;
  width: 100%;
}
.modal-footer button {
  background: #007bff;
  border-color: #007bff;
}
.modal-footer .Button_secondaryBtn__3BxUT {
  background: #fff;
}
.previewBackLink {
  top: 10px !important;
}

/* .modal-content {
  border-radius: 30px;
  overflow: hidden;
  width: 600px;
  margin-top: 80px;
} */

@media only screen and (min-width: 768px) {
  .uploadImageCard .card-body {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .backLink {
    top: 10px;
  }

  .previewBackLink {
    top: 10px !important;
  }

  .css-jp8avr-menu {
    overflow-y: auto;
    height: 6rem !important;
  }

  .css-1nmdiq5-menu {
    overflow-y: hidden;
    height: 6rem !important;
  }
}

@media only screen and (min-width: 1024px) {
  .backLink {
    top: 40px;
  }

  .previewBackLink {
    top: 10px !important;
  }

  .css-jp8avr-menu {
    height: 11rem !important;
  }

  .css-1nmdiq5-menu {
    overflow-y: hidden;
    height: 11rem !important;
  }
}

@media only screen and (min-width: 1220px) {
  .previewBackLink {
    top: 30px !important;
  }
  .backLink {
    top: 30px;
  }
}

@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1400px !important;
  }

  .css-jp8avr-menu {
    height: 16rem !important;
  }

  .css-1nmdiq5-menu {
    overflow-y: hidden;
    height: 16rem !important;
  }

  .previewCard {
    min-height: 300px !important;
  }
}

@media only screen and (min-width: 1900px) {
  .container {
    max-width: 1600px !important;
  }
  .css-jp8avr-menu {
    height: 20rem !important;
  }
}

@keyframes moveX {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  } /* Adjust the distance as needed */
  100% {
    transform: translateX(0);
  }
}

.loader path {
  animation: moveX 1.5s infinite;
}

/* Different delays to make the paths move separately */
#Path_249 {
  animation-delay: 0s;
}

#Path_250 {
  animation-delay: 0.25s;
}

#Path_251 {
  animation-delay: 0.5s;
}

@media only screen and (min-width: 720px) {
  .modal-content {
    overflow: hidden;
    width: 600px;
    margin-top: 80px;
  }
}
