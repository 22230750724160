.modalBtn {
    /* background-color: #1C224B !important; */
    border: none !important;
}

.modalBtn:hover {
    background-color: #1C224B !important;
    border: none !important;
}

.modalImage {
    max-width: 100%;
    margin: 0;
}

.quoteEmailBody {
    padding: 1rem 0 0;
}

.quoteDisclaimer {
    font-style: italic;
    font-size: 14px;
    margin-bottom: 15px;
    padding-top: 30px;
}

.filterBody {}

.filterHeader {
    padding: 0 !important;
}

.filterHeader button {
    width: 100%;
    text-align: left;
    /* background-color: #1C224B !important; */
    border: none;
    border-radius: 0;
}

.filterHeader button:focus {
    text-decoration: none;
    background-color: #1C224B;
}

.selected {
    background-color: #3c833f !important;
    color: #fff;
}

.shareItem {
    width: 6rem;
    height: 6rem;
}

.editAddDoorContainer {
    display: flex;
}

.editAddDoorContainer div {
    flex: 1;
    margin: 0 1rem;
}

.modalBody {
    background-color: #EEEDFF;
    width: 100%;
    padding: 40px;
}

.phonezip {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.SuccessModalBody {
    background-color: #EEEDFF;
    width: 100%;
    height: 200px;
    font-weight: bolder;
    padding: 3rem !important;
}

.SuccessModalBody div {
    display: flex;
    justify-content: center;
}

.SuccessModalBody p {
    display: flex;
    justify-content: center;
    padding-top: 0.2rem;
}
.closeIconContainer {
    position: absolute;
    top: 2px;
    right: -25px;
}
.closeIcon{
    cursor: pointer;
}