.canvasContainerWrapper {
    position: relative;
    flex: 1;
}
.canvasContainerWrapper>div{
    height: 100%;
    display: flex;
    align-items: center;
}
.canvasContainerWrapper>div:only-child{
    background-color: #f7f7f7;
    border: 1px solid #e9e7e7;
}

.canvasContainerWrapperLoader {
    width: 100%;
    height: 100%;
    /* background-color: red; */
    position: absolute;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.canvasContainerWrapperLoaderOverlay {
    background-color: #000000;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
}

.canvasContainerWrapperOverlayLoaderContainer {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #1C224B;
    width: 35px;
    height: 35px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    margin-bottom: 6px;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.canvasContainer {
    /* width: 100%;
    height: 40vh; */
    display: flex;
    /* align-items: center; */
    align-items: flex-start;
    justify-content: center;
    background-color: #333333;
}

.canvasContainerFitContent {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    margin-bottom: 1.4vh;
}

.mobileLandscapeHeight {
    /* height: 70vh; */
}

.canvasContainer canvas {
    height: 100%;
    width: 100%;
}

.responsiveCanvas canvas {
    width: 100%;
    /* margin: 1rem 0; */
}

.zoomBtnContainer {
    position: absolute;
    /* top: 3.4rem; */
    top: 0;
    z-index: 9;
}

.loaderText {
   color: #f3f3f3;
   z-index: 9;
   width: 300px;
   text-align: center;
}

.zoomBtnContainer button {
    border: none;
    margin: 0.4rem 0 0 0.4rem;
    background-color: #000000;
    color: #ffffff;
    border-radius: 50%;
    height: 2.2rem;
    width: 2.2rem;
    box-shadow: 1px 2px 10px 1px #999999;
    font-size: 1.4rem;
    padding: 0;
    opacity: 0.8;
}