.editContainer {
  height: calc(100dvh - 70px);
  background-color: #1c224b;
  padding: 20px 10px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 0 !important;
    margin-right: 0 !important; */
}

.zoomBtnContainer {
  position: absolute;
  /* top: 3.4rem; */
  top: 0;
  z-index: 9;
}

.zoomBtnContainer button {
  border: none;
  margin: 0.4rem 0 0 0.4rem;
  background-color: #ffffff;
  color: #000000;
  border-radius: 50%;
  height: 2.2rem;
  width: 2.2rem;
  box-shadow: 1px 2px 10px 1px #999999;
  font-size: 1.4rem;
  padding: 0;
  opacity: 0.8;
}

.dataContainer {
  margin: 1rem auto 0;
  position: relative;
}

.dataContainer canvas {
  /* width: 100% !important;
    height: 100% !important; */
}

.canvasContainer {
  width: 100%;
  height: 100%;
}

.landscapeImage {
  width: 640px;
  height: 480px;
}

.portraitImage {
  width: 480px;
  height: 640px;
}

.optionsContainer {
  margin: 1rem auto 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.deleteContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-top: 0; */
}

.deleteIcon svg {
  cursor: pointer;
}

.deleteBtn {
  background-color: #4c84ff;
  border-color: #4c84ff;
}

.button {
  background-color: #4c84ff !important;
}

.addButton {
  background-color: #4c84ff !important;
  color: #ffffff;
  border: none;
  padding: 0.4rem 1.4rem;
  /* width: 100%; */
  min-width: 8rem;
  font-size: 14px;
}
