.primaryBtn {
    background-color: #007bff;
    color: #ffffff;
    /* border: none; */
    padding: 0.4rem 1.4rem;
    /* width: 100%; */
    min-width: 8rem;
    font-size: 14px;
    border: 1px solid #007bff
}

.secondaryBtn {
    background-color: #ffffff;
    color: #ffffff;
    /* border: none; */
    padding: 0.4rem 1.4rem;
    /* width: 100%; */
    min-width: 8rem;
    font-size: 14px;
    color: #1C224B;
    border: 1px solid #1C224B
}

button:disabled {
    background-color: #aaa;
    border: none;
}